.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

label {
  font-size: 14px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
